<!--导航栏-->
<template>
<div>
    <div class="tradeTop">
        <!--左部菜单-->
        <div class="tradeTopLeft">
            <router-link :to="{name:'home'}" class="logo"><img src="@/assets/img/meke_logo.png" alt="Meke Logo Icon" style="height:24px;"></router-link>
            <!-- <div class="navSpan">
                <Dropdown>
                    <Button>
                        {{this.$t("perpetuals")}}
                        <Icon type="md-arrow-dropdown" />
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem>{{this.$t("perpetuals")}}</DropdownItem>
                        <DropdownItem>{{this.$t("margin")}}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div> -->
            <div class="navSpan">
                <Dropdown>
                    <Button>
                        {{this.$t('language')}}
                        <Icon type="md-arrow-dropdown" />
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem @click.native="changeLanguage('en-US')">English</DropdownItem>
                        <DropdownItem @click.native="changeLanguage('zh-CN')">中文</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <!--资产组合，合约交易-->
            <div class="navSpan">
                <router-link :class="{menuActive:navigationActive=='portfolio'}" :to="{name:'portfolio'}">{{this.$t("portfolio")}}</router-link>
                <router-link :class="{menuActive:navigationActive=='trade'}" :to="{name:'trade'}">{{this.$t("trade")}}</router-link>
                <router-link :to="{ name: 'portfolio', query:{tab:'airdrop'} }">{{ this.$t("airdrop") }}</router-link>
            </div>
            <!--保证金-->
            <!-- <div class="navSpan">
                <router-link :class="{menuActive:navigationActive=='stock'}" :to="{name:'stock'}">{{this.$t("margin")}}</router-link>
            </div> -->
        </div>
        <!--右部菜单-->
        <div class="tradeTopRight">
            <div v-if="isInitWallet" class="rightMenus alignCenter" style="height: 30px;">
                <div v-if="bindInfo.isVip==2" class="mr10 red active_t" @click="defineRelation()">{{$t('activateVIP')}}</div>
                <div v-if="bindInfo.isVip==1" class="mr10 orange fontb">VIP</div>
            </div>
            <!--反馈-->
            <Tooltip v-if="isInitWallet" placement="top-end">
                <div slot="content">{{$t('feedback')}}</div>
                <div class="actionWpTitle">
                    <div class="rightMenus">
                        <div class="rightMenuItem" @click="showFeedbackPop()">
                            <img :src="require('@/assets/img/feedback.svg')" style="width:20px;height:20px;opacity:0.5;" />
                        </div>
                    </div>
                </div>
            </Tooltip>
            <!--通知-->
            <Tooltip placement="top-end">
                <div slot="content">{{$t('notice')}}</div>
                <div class="actionWpTitle">
                    <div class="rightMenus">
                        <div class="rightMenuItem" @click="showNoticePop()" >
                            <span class="unreadBox" style="position:relative;">
                                <img :src="require('@/assets/img/notice.svg')" style="width:20px;height:20px;opacity:0.5;" />
                                <span v-if="getIsUnread && false" class="unread"></span><!--红点-->
                            </span>
                            
                        </div>
                    </div>
                </div>
            </Tooltip>
            <Tooltip v-if="isInitWallet" placement="top-end">
                <div slot="content">{{$t('InvitedFriends')}}</div>
                <div class="actionWpTitle">
                    <div class="rightMenus">
                        <div class="rightMenuItem" @click="getList();">
                            <img :src="require('@/assets/img/yaoqing.svg')" style="width:20px;height:20px;opacity:0.5;" />
                        </div>
                    </div>
                </div>
            </Tooltip>
            <!--分享-->
            <Tooltip v-if="isInitWallet" placement="top-end">
                <div slot="content">{{$t('share')}}</div>
                <div class="actionWpTitle">
                    <div class="rightMenus">
                        <div class="rightMenuItem" @click="showSharePop()">
                            <img :src="require('@/assets/img/share.svg')" style="width:20px;height:20px;opacity:0.5;" />
                        </div>
                    </div>
                </div>
            </Tooltip>

            <!--钱包菜单-->
            <div v-if="isInitWallet" class="walletAddress alignCenter ml10">
                <Dropdown placement="bottom-end">
                    <Button>
                        <span class="alignCenter">
                            <img class="tradeTypeIcon" :src="require('@/assets/img/'+tradeTypeIcon)" alt="">
                            <span class="ml10">{{getwalletAddr}}</span>
                            <Icon type="md-arrow-dropdown" />
                        </span>
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem>
                            <div @click="copyAddr()" class="menus"><Icon type="ios-copy-outline" size="20" class="mr10"></Icon>{{$t('copyAddr')}}</div>
                        </DropdownItem>
                        <!-- <DropdownItem>
                            <div class="menus"><Icon type="ios-cloud-outline" size="20" class="mr10"></Icon>在Etherscan中开仓</div>
                        </DropdownItem> -->
                        <DropdownItem>
                            <div @click="exitConnect()" class="exit_hover">
                                <img class="exit1 mr10" :src='require("@/assets/img/exit.svg")' style="width:20px;height:20px;" />
                                <img class="exit2 mr10" :src='require("@/assets/img/exit_active.svg")' style="width:20px;height:20px;" />{{$t('exitConnect')}}
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <!--连接钱包-->
            <div v-else class="ml10">
                <Button type="info" @click="isconnectWallet=true">
                    {{this.$t("connectWallet")}}
                </Button>
            </div>
        </div>
    </div>
    <!-- 邀请人弹窗 -->
    <Modal  v-model="isPerson" width="400px" :closable="false">
        <template slot="header">
            <span>{{ $t('InvitedFriends') }}</span>
        </template>
        <div>
            <div class="amountBox" style="max-height:60vh;overflow:auto">
                <div v-for="(v,o) in list" :key="v.referree">#<span>{{o+1}}</span>：{{v.referree | addr}}</div>
                <div v-if="list.length==0" class="center">{{ $t('none') }}</div>
            </div>
        </div>
        <template slot="footer">
            <Button type="info" size="large" long @click="isPerson=false">{{$t('confirm')}}</Button>
        </template>
    </Modal>
    <!--分享弹窗-->
    <Modal  v-model="isSharePop" width="400px" :closable="false">
        <!--标题-->
        <template slot="header">
            <span></span>
        </template>
        <!--内容-->
        <div>
            <div class="flexCenter mt10">
                <vue-qr :text="myShareLink"
                        :correctLevel="3"
                        :size="250"
                        :margin="10"
                        colorDark="#000000"
                        colorLight="#ffffff"
                        backgroundColor="white"
                        backgroundDimming="white"
                        :logoSrc="require('@/assets/img/meke_icon.png')"
                        :logoScale=".2"
                        :logoMargin="5"
                        logoBackgroundColor="white"
                ></vue-qr>
            </div>
            <div class="mt20 center">{{$t('promotionRewardDesc')}}</div>
            <div class="mt15 actionWpTitle">{{$t('promotionLink')}}</div>
            <div class="mt10">
                <Input :value="myShareLink" type="text" placeholder="" max-length="50" readonly />
            </div>
            <div class="mt15 actionWpTitle">{{$t('promotionPreference')}}</div>
            <div class="amountBox">
                <div>{{ $t('discount0_0') }} <span style="color:#aaa">{{ $t('discount0_em') }}</span> {{ $t('discount0_1') }}</div>
                <div>{{$t('discountOne')}}</div>
                <div>{{$t('discountTwo')}}</div>
            </div>
        </div>
        <!--按钮-->
        <template slot="footer">
            <Button type="info" size="large" long @click="copyCode()">{{$t('copyLink')}}</Button>
        </template>
    </Modal>
    <!--连接钱包弹窗-->
    <ConnectWallet v-model="isconnectWallet" key="1"></ConnectWallet>
    <!--反馈弹窗-->
    <Modal v-model="isFeedbackPop" width="400px" :mask-closable="false">
        <template slot="header">
            <span>{{$t('feedback')}}</span>
        </template>
        <div class="amountBox">
            <div class="flexBetween">
                <textarea v-model="feedback" rows="8" class="textareaStyle" style="width:100%;" maxLength="1000" :placeholder="$t('feedDetail')"></textarea>
            </div>
        </div>
        <template slot="footer">
            <Button type="info" size="large" long @click="sendFeedback()" :disabled="!feedback">
                <span>{{$t('send')}}</span>
            </Button>
        </template>
    </Modal>
</div>
</template>

<script>
import ConnectWallet from "@/components/model/connectWallet.vue"
import exInput from "@/components/common/exInput/index.vue"
import { getRelation,defineRelation,sendMsg,relation_lower } from "@/api/http"
import vueQr from "vue-qr"; //引入二维码生成组件
import WalletUtil from "@/utils/walletUtil"
import ContractUtil from "@/utils/contractUtil"

let initTimeoutId=0
export default {
    components: {
       ConnectWallet,exInput,vueQr
    },
    data () {
        return {
            tradeTypeIcon:'',//图标
            isconnectWallet:false,//连接钱包弹窗
            isSharePop:false,//分享弹窗
            
            isFeedbackPop:false,//反馈
            navigationActive:'',//活动菜单项
            form:{
                code:''
            },
            myShareLink:'',//分享链接
            bindInfo:{
                isVip:'',//是否绑定。1：已激活，2：未激活；其他未绑定。
            },//绑定上下级信息
            feedback:'',//反馈
            isPerson:false,//邀请人
            list:[],
        };
    },
    mounted () {
    },
    methods: {
        getList(){
            this.isPerson = true
            relation_lower({ address: this.addr }).then(res => {
                if(res.code == 200){
                    this.list=res.data || [];
                }
            })
        },
        init(){
            clearTimeout(initTimeoutId)
            if(!this.addr || !this.isInitWallet){
                initTimeoutId=setTimeout(()=>this.init(),2000)
                return
            }
            if(this.blockchain.exchange){
                WalletUtil.exchange.at(this.blockchain.exchange)
            }
        },
        //确认上下级关系
        defineRelation(){
            WalletUtil.exchange.at(this.blockchain.exchange).then((instance) => {
                return instance.activateReferral(this.bindInfo.inviteAddress, {from:this.addr})
                .then((res) =>{
                    this.$Message.success(this.$t('cctivationSucceeded'));
                    this.bindInfo.isVip=1;
                    this.sendDefineRelation();
                });
            }).catch(err=>{
                this.$Message.error(this.$t('operationFailed'));
                console.warn(err.message)
            });
        },
        //通知后端激活成功
        sendDefineRelation(){
            defineRelation({
                address:this.addr
            }).then(res=>{
                sendMsg({cmd:'config',data:this.blockchain.perpetual});//获取资金费率
            }).catch(err=>{
                console.warn(err);
            });
        },
        //显示分享弹窗，获取推广码
        showSharePop(){
            this.isSharePop=true;
        },
        //显示通知弹窗
        showNoticePop(){
            this.$emit('showNoticePop',true);
        },
        //显示反馈弹窗
        showFeedbackPop(){
            this.isFeedbackPop=true;
        },
        //发送反馈
        sendFeedback(){
            this.feedback='';
            this.isFeedbackPop=false;
            this.$Message.warning(this.$t('underConstruction'));
        },
        //断开连接
        exitConnect(){
            this.$store.commit("exitLogin");
            //发送退出登录消息
            sendMsg({cmd:'logout'});
            WalletUtil.onDisconnect();
        },
        //复制推广码
        copyCode(){
            this.$copyText(this.myShareLink).then((e)=>{
                this.$Message.success(this.$t('copySuccess'));
            }, function (e) {
                //this.$Message.error(this.$t('operationFailed'));
            })
        },
        //复制地址
        copyAddr(){
            this.$copyText(this.addr).then((e)=>{
                this.$Message.success(this.$t('copySuccess'));
            }, function (e) {
                // this.$Message.error(this.$t('operationFailed'));
            })
        },
        //切换语言
        changeLanguage(lang){
            this.$i18n.locale = lang;
            localStorage.setItem('language',lang);
        },
        //查询当前用户是否拥有上下级关系
        getRelation(){
            getRelation({
                address:this.addr,
            }).then(res=>{
                if(res.code == 200){
                    let bindInfo=res.data || {};
                    if(bindInfo.isVip==1){  //激活成功
                    }else if(bindInfo.isVip==2){    //未激活
                        this.bindInfo.isVip='';//置空
                        this.findRelation();
                    }
                    this.bindInfo=bindInfo;
                }else{
                    // this.$Message.error(this.$t('operationFailed'));
                }
            }).catch(err=>{
                console.warn(err);
            });
        },
        //区块链上查询是否激活成功
        async findRelation(){
            ContractUtil.exchange.at(this.blockchain.exchange).then(instance=>{
                instance.referrals(this.addr).then(res=>{
                    console.log('res:',res);
                    if(res!='0x0000000000000000000000000000000000000000'){
                        this.bindInfo.isVip=1;
                        this.sendDefineRelation();
                    }else{
                        this.bindInfo.isVip=2;
                    }
                })
            });
        }
    },
    computed:{
        blockchain(){
            return this.$store.state.blockchain;
        },
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //钱包地址
        getwalletAddr(){
            return this.$store.state.app.wallet.address.substr(0,6)+"****"+this.$store.state.app.wallet.address.substr(38,4);
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        //是否未读
        getIsUnread(){
            return this.$store.getters['system/getIsUnread'];
        }
    },
    watch:{
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.init();
                    this.getRelation();
                    //钱包图标
                    let walletActive=JSON.parse(sessionStorage.getItem('walletActive'));
                    this.tradeTypeIcon=(walletActive&&walletActive.img) || '';
                    //二维码
                    let url=document.URL;
                    this.myShareLink=url.split('?')[0]+`?addr=${this.addr}`;
                }else{
                }
            },
            immediate: true
        },
       "$route.path": {
            handler(val) {
                this.navigationActive=val.replace("/", "");
            },
            immediate: true
        },
    },
};
</script>
<style lang="less" scoped>
.ivu-btn:focus{outline:none;box-shadow:none;}
.tradeTop{height:48px;.flexBetween;border-bottom:1px solid @borderColor;
    .ivu-btn-default{background-color:transparent;border-color: transparent;color:@color;
        &:hover{background: #000;color:@success-color;}
    }
    .tradeTopLeft{display:flex;
        .logo{position: relative;padding:0 10px;height:48px;.flexCenter;
            img{height:32px;}
            &:after{.afterLine;}
        }
    }
    .tradeTopRight{ padding-right:10px; .alignCenter;

    }
}

.navSpan{
    padding:0 10px;height:48px;.flexCenter;position:relative;
    &:after{.afterLine;}
    >a{color:@color;padding:0 15px;height:32px;.flexCenter;border-radius:@border-radius-base;
        &:hover{background: #000;color:@success-color;}
    }
}
.menuActive{
    background: #000;color:@success-color;
}
.walletAddress{
    width:150px;
    overflow:hidden;
}
.menus{.alignCenter;color:@grey;}
.menus:hover{color:#fff;}
.exit_hover{.alignCenter;color:@grey;}
.exit_hover:hover{color:red;}
.exit_hover .exit1{display:inline-block;}
.exit_hover .exit2{display:none;}
.exit_hover:hover .exit1{display:none;}
.exit_hover:hover .exit2{display:inline-block;}

//右部菜单
.rightMenus{
    border-right: 1px solid #2d2d3d;
    // border-left: 1px solid #2d2d3d;
    padding: 0 10px;
}
.rightMenuItem{
    .flexCenter;
    width: 30px;
    height: 30px;
    border-radius:8px;
    &:hover{
        background: #000;
    }
}
//分享
/deep/ .ivu-modal-header{
    // border-bottom: 1px solid @borderColor !important;
    border: none !important;
    // padding:0 0;
}
.titleList>span{
    padding: 5px 8px;
    border-radius: 5px;
    cursor: pointer;
    color:@color;
}
.titleList>span.active{
    background-color:#111;
    cursor: default;
    color: white;
}
.amountBox{
    .mt10;
    background: #000;
    border-radius: 4px;
    padding: 13px 15px;
    color:#666;
}
.actionWpTitle{
    color:@color;
}
.textareaStyle{
    background: none;
    border: none;
    color: @font-color;
    font-size: 16px;
}
.unread{
    width:6px;
    height:6px;
    background-color:@error-color;
    border-radius:50%;
    position:absolute;
    top:-2px;
    right:-2px;
}
.unreadBox{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
</style>