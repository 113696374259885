//所有钱包配置
export function getWallets(){
    return [{
        id:'1',
        name:'Connect',
        img:'wallets/connect.svg'
    },
    //     {
    //     id:'2',
    //     name:'MetaMask',
    //     img:'wallets/MetaMask.svg'
    // },{
    //     id:'3',
    //     name:'Coinbase Wallet',
    //     img:'wallets/CoinbaseWallet.svg'
    // },
    // {
    //     name:'Trust Wallet',
    //     img:'wallets/TrustWallet.svg'
    // },{
    //     name:'TokenPocket',
    //     img:'wallets/TokenPocket.svg'
    // },{
    //     name:'Rainbow Wallet',
    //     img:'wallets/RainbowWallet.svg'
    // },{
    //     name:'Ledger',
    //     img:'wallets/Ledger.svg'
    // },
    {
        id:'4',
        name:'WalletConnect',
        img:'wallets/WalletConnect.svg'
    }];
}
