<template>
<div>
    <Modal
    :closable="false"
    :transfer="false"
    :value="isShow"
    width="400"
    :mask-closable="true"
    @on-cancel="close()"
    >
        <!--菜单-->
        <div slot="header" class="CWMheader">
            <h3>{{this.$t("connectYourWallet")}}</h3>
        </div>
        <!--内容-->
        <div @click="close()" class="CWMcontentMain">
            <div v-for="(item,index) in dataList" :key="index" class="CWMCMitem">
                <button type="info" class="CWMCMImain walletBtn" @click="connectWalletBtn(item,index)">
                    <div class="alignCenter">
                        <img class="tradeTypeIcon" :src="require('@/assets/img/'+item.img)" style="width:18px;height:18px;">
                        <span class="ml10">{{item.name}}</span>
                    </div>
                </button>
            </div>
        </div>
        <!--底部栏-->
        <div slot="footer" class="CWMfooter">
            <div>
                <!--关于钱包的描述-->
                <!-- <span>{{this.$t("aboutWallets")}}</span>
                <Icon size="14" type="md-arrow-up" /> -->
            </div>
            <div @click="close()"><span>{{this.$t("skipForNow")}}</span><Icon size="14" type="md-return-right" /></div>
        </div>
    </Modal>
    <!--连接钱包状态弹窗-->
    <Modal v-model="isConnectWalletPop" class="connectWalletPop" width="400px" :transfer="false" :closable="false">
        <template slot="header">
            <span style="font-size:24px;">{{$t('onConnection')}}</span>
        </template>
        <div class="flexCenter" style="height:150px;">
            <div class="pswp__preloader__icn">
                <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
                </div>
            </div>
        </div>
        <template slot="footer">
            <span class="resetBtn printer" @click="isConnectWalletPop=false;$emit('change',true);"><Icon type="md-undo" class="mr5"/>{{$t('retry')}}</span>
        </template>
    </Modal>
</div>
</template>
<script>
import WalletUtil from "@/utils/walletUtil"
import { sendMsg } from "@/api/http"
import { getWallets } from './const/const'
export default {
    name: '',
    model:{
        prop:'isShow',
        event:'change'
    },
    props: {
        //连接钱包弹窗，是否显示
        isShow:{
            type:Boolean,
            default:false
        },
    },
    components: {

    },
    data() {
        return {
            dataList:getWallets(),//钱包列表
            isConnectWalletPop:false,//连接中
        }
    },
    mounted() {
    },
    methods: {
        //关闭
        close(){
            this.$emit("change",false);
        },
        //连接钱包
        connectWalletBtn(item,index){
            // console.log('选择钱包',item);
            switch(item.name){
                case 'imToken':
                    // this.walletInitCode(item);
                    break;
                case 'MetaMask':
                    this.close();
                    this.isConnectWalletPop=true;
                    // this.walletInit(item);
                    break;
                case 'Coinbase Wallet':
                    // this.walletInitCoinbase(item);
                    break;
                case 'WalletConnect':
                    // this.walletInitCode(item);
                    break;
            }
            sessionStorage.setItem('walletActive',JSON.stringify(item));
            WalletUtil.walletInit(()=>{
                this.login(item);
                this.isConnectWalletPop=false;
            })
        },
        //登录
        login(item){
            sessionStorage.setItem('walletActive',JSON.stringify(item));
            this.close();
            sendMsg({cmd:'login',data:this.addr});//发送登录消息
        }
    },
    computed:{
        addr(){
            return this.$store.state.app.wallet.address;
        },
    },
    watch:{
    }
};
</script>
<style lang='less' scoped>
.ivu-modal-content{
    background: @white; overflow: auto;color:@text-color;
    >div{
        background-color: rgba(0, 0, 0, 0.5);border:none;padding:0;
    }
}
.CWMheader{color: @color;}
.CWMfooter{.flexBetween;
    >div{display:flex;align-items:center;
        >i{.ml5;}
        &:hover{cursor: pointer;color: @color;}
    }
}
.CWMcontentMain{
    background: @black;border-radius: 6px;min-height: 200px;padding:20px;
    .CWMCMitem{
        padding:0;border-radius: 6px;display:inline-block;.mr10;.mb10;
        
    }
}
.CWMCMImain{
    >span{
        display: flex;align-items: center;
    }
}
.walletBtn {
    font-family: Relative Pro;
    font-weight: 500;
    -webkit-font-feature-settings: "zero" 0;
    font-feature-settings: "zero" 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @theme-color;
    color: #c3c2d4;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 16px;
    border: none;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;
    height: 36px;
    min-height: 36px;
    &:hover{opacity:0.8;}
}
/deep/ .connectWalletPop .ivu-modal-header{
    padding-top:30px;
}
.resetBtn{
    color:@font-color;font-size:18px;
    opacity:0.7;
}
/**css加载中 */
.pswp__preloader__icn {
    opacity:0.75;
    width: 80px;
    height: 80px;
    -webkit-animation: clockwise 1000ms linear infinite;
    animation: clockwise 1000ms linear infinite;
}
.pswp__preloader__cut {
    position: relative;
    width: 40px;
    height: 80px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.pswp__preloader__donut {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    border: 3px solid #6179fa;
    border-radius: 50%;
    border-left-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    margin:0;
    -webkit-animation: donut-rotate 2000ms cubic-bezier(.4,0,.22,1) infinite;
    animation: donut-rotate 2000ms cubic-bezier(.4,0,.22,1) infinite;
}

@-webkit-keyframes clockwise {
    0% { -webkit-transform: rotate(0deg) }
    100% { -webkit-transform: rotate(360deg) }
}
@keyframes clockwise {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
@-webkit-keyframes donut-rotate {
    0% { -webkit-transform: rotate(0) }
    50% { -webkit-transform: rotate(-140deg) }
    100% { -webkit-transform: rotate(0) }
}
@keyframes donut-rotate {
    0% { transform: rotate(0) }
    50% { transform: rotate(-140deg) }
    100% { transform: rotate(0) }
}
</style>