<!--通知弹窗-->
<template>
<div v-if="isShow" class="dialog">
    <div class="bgLayer" @click="close();"></div>
    <div class="menuPop">
        <!--标题-->
        <div class="menuTitle">
            <div class="alignCenter">{{$t('notice')}}</div>
        </div>
        <!--内容-->
        <div class="" @scroll="scrollEvent" style="overflow:auto;">
            <div v-for="(item,index) in notice_list" :key="index" class="noticeList">
                <div class="notice-item">
                    <div class="title">{{ item.title }}</div>
                    <div class="content">{{ item.message }}</div>
                    <div style="display:flex;justify-content:space-between;width:100%;">
                        <div class="time">{{ item.time }}</div>
                        <div class="read-btn" v-if="0" @click="e=>setReaded(item)">Readed</div>
                    </div>
                </div>
            </div>
            <div v-if="form.loading" class="notice">{{ $t('loading') }}</div>
            <div v-else-if="notice_list.length==0" class="notice">{{$t('noNewNotice')}}</div>
        </div>
    </div>
</div>
</template>

<script>
import { getMessageUnreaded,setMessageReaded } from "@/api/http"
export default {
    name: '',
    model:{
        prop:'isShow',
        event:'change'
    },
    props: {
        //是否显示弹窗
        isShow:{
            type:Boolean,
            default:false
        }
    },
    components: {
    },
    data () {
        return {
            notice_list:[],//通知列表
            form:{
                page:1,
                size:20,
                loading:true,//加载中
                finished:false,//是否完成
            }
        }
    },
    mounted() {
        
    },
    methods: {
        // 滚定监听
        scrollEvent(e) {
            // console.log('滚动',e.currentTarget.scrollTop,e.currentTarget.clientHeight,e.currentTarget.scrollHeight);
            if (Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=e.currentTarget.scrollHeight-20) {   //计算的容差：20px
                if(!this.form.finished &&  !this.form.loading){
                    // console.log('滚动到底部');
                    this.form.page++;
                    this.getMessageUnreaded();
                }
            }
        },
        setReaded(item){
            if(this.addr){
                setMessageReaded(item.trader||this.addr, item.id).then(res=>{
                    this.notice_list = this.notice_list.filter(v=>v.id!=item.id);
                    this.$Message.success(this.$t('operationSucceeded'));
                }).catch(err=>{
                    console.warn(err.message);
                    this.$Message.error(this.$t('operationFailed'));
                })
            }
        },
        //获取通知
        getMessageUnreaded(){
            let addr=this.addr;
            if(!addr){
                return;
            }
            let d={
                page:this.form.page,
                size:this.form.size,
            }
            this.form.loading=true;
            getMessageUnreaded(addr,d).then(res=>{
                this.form.loading=false;
                if(res.code == 200){
                    let list=(res.data.records || []).map(item=>{
                        if(item.type=='liquidate'){
                            item.title=this.$t('LiquidateNotice')
                            item.message=this.$t('LiquidatedTip');
                        }
                        item.time=item.createdAt.substring(0,19).replace('T',' ')+' UTC';
                        return item;
                    });
                    if (list.length >= d.size) {
                        this.form.finished = false;
                    } else {
                        this.form.finished = true;
                    }
                    if(d.current==1){
                        this.notice_list=list;
                    }else{
                        this.notice_list=this.notice_list.concat(list);
                    }
                }else{
                    this.$Message.error(this.$t('operationFailed'));
                }
            })
        },
        //选择交易对
        close(){
            this.$emit("change",false);
        },
    },
    computed:{
        //多语言切换
        lang(){
            return this.$i18n.locale.toString();
        },
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
    },
    watch:{
        //监听多语言切换
        lang:function(){
            console.log(this.lang)
        },
        isShow(val,oldVal){
            if(val){
                this.$store.commit('system/setIsUnread',false);
                this.form.page=1;
                this.notice_list=[];
                this.getMessageUnreaded();
            }
        }
    }
}
</script>

<style scoped lang='less'>
.dialog {
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    // -webkit-animation: _367xTR-Kt2MnztKjR8ic4V .25s ease-in-out;
    // animation: _367xTR-Kt2MnztKjR8ic4V .25s ease-in-out;
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
}
.bgLayer {
    height: 100%;
    flex: 1;
}
.menuPop {
    height: 100%;
    min-width: 300px;
    max-width: 300px;
    background-color: @theme-color;
    border-left: 1px solid @border-color;
    display: flex;
    flex-direction: column;
}
.menuTitle {
    font-family: Relative Pro;
    font-weight: 500;
    -webkit-font-feature-settings: "zero" 0;
    font-feature-settings: "zero" 0;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    min-height: 64px;
    padding: 0 24px;
    color: @font-color;
    border-bottom: 1px solid @border-color;
}
.notice {
    .op5;
    color:@font-color;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    padding: 28px 12px;
}
.noticeList{
    margin: 8px;
    color: #c7c8d8;
    background: #2c2c2c;
}
.notice-item {
    display:flex;
    flex-direction: column;
    gap:8px;
    padding:8px;
}
.title{
    border-bottom: 1px solid @border-color;
    padding:4px 0;
}

.time {
    font-size:0.8em;
    opacity:0.75;
}

.read-btn {
    display:inline-block;
    white-space:nowrap;
    border-radius:4px;
    padding:4px 8px;
    border:1px solid #ddd;
    cursor:pointer;
    zoom:0.75;
}

.f12{
    font-size: 12px;
    .op5;
}
.content{
    .op8;
}
.green_tag,.red_tag{
    padding: 2px 5px;
    border-radius: 5px;
    color: white;
    .op8;
}
.green_tag{
    background: @success-color;
}
.red_tag{
    background: @error-color;
}
.footer{
    padding: 8px;
    border-top: 1px solid @border-color;
    text-align: right;
}
</style>