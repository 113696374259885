<!--交易面板-->
<template>
<div class="trade">
    <Topbar @showNoticePop="isNoticePop=true;"></Topbar>
    <div class="mainContent">
        <router-view />
    </div>
    <notice v-model="isNoticePop"></notice>
</div>
</template>

<script>
import Topbar from "@/components/topbar/index.vue";
import notice from '@/components/notice/index.vue'
export default {
    name: "",
    props: {},
    components: {
        Topbar,notice
    },
    data() {
        return {
            isNoticePop:false,//通知弹窗
        };
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped lang='less'>
.trade {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.mainContent {
    flex: 1;
    color: @color;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

</style>